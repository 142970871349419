import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Parser from 'html-react-parser';

function News() {

    const [news, setNews] = useState();

    useEffect(() => {
        axios.get('https://admin.bstlglobal.com/api/v1/news_list')
            .then(response => setNews(response.data))
            .catch(error => console.log(error))
    }, [0])
    return (
        <>
            <Container fluid className='mt-5 mb-5' >
                <div>
                    {news &&
                        news.map((element, index) => {
                            return (
                                <>
                                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Col lg={3} style={{ marginLeft: '2.5%' }}>
                                            <div>
                                         <img src={"https://admin.bstlglobal.com/"+JSON.parse(element.images)[0]}  width={'100%'} />
                                            </div>
                                        </Col>
                                        <Col lg={6} style={{ marginRight: '2%', marginLeft: '2%' }}>
                                            <div style={{ textAlign: "justify" }} >
                                                <h4 style={{ fontSize: '21px' }}>{element.title}</h4>
                                                <h6 style={{ fontSize: '15px' }}>{element.sub_title}</h6>

                                                <p style={{ fontSize: '14px', textAlign: 'justify' }} >
                                                    {Parser(element.content.substring(0, 200))}
                                                </p>
                                               <Link to={""+element.id}> <button className='btn btn-primary'>Read more</button></Link>
                                            </div>
                                        </Col>

                                    </Row>
                                </>
                            )
                        })
                    }

                </div>
            </Container>

        </>
    )
}

export default News
