import React from 'react'
import { Container, Row ,Col} from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import {BsFillTelephoneFill} from "react-icons/bs";
import{MdOutlineMailOutline, MdLocationOn}from 'react-icons/md';
function Footer() {
    const location = useLocation()
    const currentYear = new Date().getFullYear();


if(location.pathname!=="/vision"){
  return (
    <div className='footer-container'>
    <Container     >
        <Row  >
           
            <Col lg={3} md={3} sm={12} xs={12} className='mt-3 mb-3'>
                <div className="footer-div-size">
                <u> <h6>About us</h6></u>
                    {/* <p><Link to="/page/bstlprofile">About Us</Link></p> */}
                    {/* <p><Link to="/vision">Our Business</Link></p> */}
                    <p><Link to="/page/bstlprofile">BSTL Profile</Link></p>
                    <p><Link to="/images">Media</Link></p>
                    <p><Link to="/tems_and_conditions">Terms & Condtions</Link></p>
                    <p><Link to="/privacy_policy">Privacy Policy</Link></p>

                    


                    
                    {/* <p><Link to="/news">News</Link></p> */}
                </div>

            </Col>
          <Col lg={3} md={3}  sm={12}  xs={12} className='international-footer mt-3 mb-3'>
                <div className="footer-div-size">
                <u>   <h6>International</h6></u>
                    <p><Link to="/oman">OMAN</Link></p>
                    <p><Link to="/usa">  UAE </Link></p>
                    <p><Link to="/ksa">KSA</Link></p>
                    <p><Link to="/qatar">QATAR</Link></p>
                    <p><Link to="/india">INDIA</Link></p>
                </div>

            </Col>

            {/* <Col lg={3} className='mt-3 mb-3'>
                <div className="footer-div-size">
            <u>   <h6>Important Links</h6></u>
            <p> <Link to='/tems_and_conditions'>Terms & Condtions</Link> </p>
                    <p><Link to='/privacy_policy'> Privacy Policy</Link></p>
                    </div>
            </Col> */}
            <Col lg={3} md={3}  sm={12}  xs={12} className='mt-3 mb-3' >
                <div className="footer-div-size">
                <u>   <h6>Contact</h6></u>
                    <p> <MdOutlineMailOutline/> info@bstlglobal.com</p>
                    <p><BsFillTelephoneFill/> +968 2456 0081</p>
                    {/* <p> <Link to='/tems_and_conditions'>Terms & Condtions</Link> </p>
                    <p><Link to='/privacy_policy'> Privacy Policy</Link></p> */}
                   
                   
                   
                </div>

            </Col>
            {/* <Col lg={2} md={3}  sm={12}  xs={12} className='international-footer mt-3 mb-3'>
                <div>
                <u>      <h6>Follow-Us</h6></u>
                    <Row className='footer-icons'>
                        <Col lg={3}><img src='/Asets/footer/social_icons/facebook.svg' width={'40px'}/></Col>
                        <Col lg={3}><img src='/Asets/footer/social_icons/instagram.svg' width={'40px'}/></Col>
                        <Col lg={3}><img src='/Asets/footer/social_icons/linkedin.svg' width={'40px'}/></Col>
                    </Row>
                    
                </div>

            </Col> */}
  <hr></hr>
  <div>
  <p style={{fontSize:'12px',marginLeft:'8%',marginBottom:'2%'}}>Copyright ©  {currentYear} bstlglobal oman.</p>
  </div>

  </Row>
           

    </Container>
      
    </div>
  )
}
}

export default Footer
