import React from 'react'
import { Col,Row, Container,Nav,Button } from 'react-bootstrap'
import { Link, useNavigate} from 'react-router-dom'

function Data() {
  const uae = useNavigate()
  const ksa = useNavigate()
  const qatar = useNavigate()
  const india =useNavigate()
  const oman =useNavigate()
  const viewall = useNavigate()

  return (
    <>
      <Container fluid className='international-page' >
      
      
          <Nav  >
           
          <Col lg={1} sm={6} xs={6} md={2}  >
            <Nav.Item className='view-all-btn'>
         <Nav.Link>
         <Button onClick={(e)=>viewall('/internationals')} className='international-btn' >View All</Button>              </Nav.Link>
              </Nav.Item>
            </Col>
            <Col  lg={1} sm={6} xs={6}md={2} >
            <Nav.Item>
             <Nav.Link onClick={(e)=>india('/oman')}><img src="/Asets/MicrosoftTeams-image (41).png" /><p >OMAN</p></Nav.Link>
            </Nav.Item>
            
            </Col>
         
            <Col  lg={1} sm={6} xs={6}md={2} >
            <Nav.Item>
              <Nav.Link onClick={(e)=>uae('/usa')}  ><img src='Asets/uae_falg.jpg'/> <p >UAE</p></Nav.Link>
            </Nav.Item>
            </Col>
            <Col  lg={1} sm={6} xs={6}md={2}  >
            <Nav.Item>
              <Nav.Link onClick={(e)=>ksa('/ksa')}><img src='Asets/saudi_flag.jpg'/><p>KSA</p></Nav.Link>
            </Nav.Item>
            </Col>
            <Col  lg={1} sm={6} xs={6}md={2} >
            <Nav.Item>
            <Nav.Link onClick={(e)=>qatar('/qatar')}><img src='Asets/quatar_flag.jpg'  className='qatar-img'/> <p >QATAR</p></Nav.Link>
            </Nav.Item>
            </Col>
           
            <Col  lg={1} sm={6} xs={6}md={2} >
            <Nav.Item>
             <Nav.Link onClick={(e)=>india('/india')}><img src='Asets/india_flag.jpeg' /><p >INDIA</p></Nav.Link>
            </Nav.Item>
            
            </Col>
           
          
          </Nav>
          <hr style={{width:'93.4%',position:'relative',left:'3%'}}></hr>
          <Row >
      <Col lg={6}md={6}  >
       <div>



<p><Link to="/page/accessories" >Ash Amaira Accessories Trading LLC</Link></p>


<p><Link to="/page/ipark">I Park Robotic Parking & Elevators LLC</Link></p>




  

 
     </div>    
          
       
        </Col>
        <Col lg={6}md={6} >
      <div >


     
      <p><Link to="/page/intelligent">Intelligent Parking & Elevators LLC</Link></p>
     
      <p><Link to="/page/sadad">Sadad International LLC</Link></p>
     

     
  
        
       
      
      </div>
        </Col>
        </Row>
      
         
          </Container>
    </>
  )
}

export default Data
