import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import {BsFillTelephoneFill} from "react-icons/bs";
import{MdOutlineMailOutline, MdLocationOn}from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';
function Contact() {

  const [userData, setUserData] = useState(
    {
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
    }
  );
  const changehandler = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value })

  }
  const formdatasubmit = (e) => {
    console.log('submitted')
    e.preventDefault();
    axios.post('https://admin.bstlglobal.com/api/v1/contact',
      userData
    )
      .then(function (response) {
      toast.success('You request has been registered we will get back to you soon')
      console.log(response)
      setUserData({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
      })
      })
      .catch(function (error) {
        console.log(error)
        var obj =error.response.data.message
        var result = Object.keys(obj).map((key) => [Number(key), obj[key]]);
        result.map((element,index)=>{
          element.map((sub_element, index)=>{
            if(index!==0){
              toast.error(sub_element[0])
            }
          })
        })
      });
  
      
     
    

  }
  const { name, email, phone, subject, message } = userData
  return (
    <>
    <Container fluid className='contact-page'>
      <ToastContainer/>
 
      <Row className='row-1 mt-5'>
        <Col lg={10}>
          <Row className='row-2'>
        <Col lg={4}>
          <div style={{display:'flex',justifyContent:'center'}}>
          <div className='icon-div'>
             <span> <BsFillTelephoneFill /></span>
        </div>
        </div>
        <div className='mt-3 div-content'>
        <p>+968 2456 0081</p>
        </div> 

        </Col>
        <Col lg={4}>


        <div style={{display:'flex',justifyContent:'center'}}>
          <div className='icon-div'>
          <span> <MdOutlineMailOutline /></span> 
        </div>
        </div>
        <div className='mt-3 div-content'>
        <p>info@bstlglobal.com</p>
        </div> 

        

        </Col>
        <Col lg={4}>
        <div style={{display:'flex',justifyContent:'center'}}>
          <div className='icon-div'>
          <span> <MdLocationOn /></span> 
        </div>
        </div>
        <div className='mt-3 div-content'>
        <p>P.O. Box 253,  Muscat-118, Sultanate of Oman</p>
        </div> 

        

        </Col>
        </Row>
        </Col>
      </Row>
      <Row className='mb-5 mt-4 row-left-contact'>
        <Col lg={6}  className='form-col'>
        
        <Form className='form'>
          <Row>
            <Col lg={6} md={12} >
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Control type="text" placeholder="Name" width="50px" value={name} name="name" onChange={changehandler} />

       
      </Form.Group>
      </Col>
      <Col lg={6}>
      <Form.Group className="mb-3" controlId="formBasicPassword">
      <Form.Control type="email" placeholder="Email" value={email} name="email" onChange={changehandler} />

      </Form.Group>
      </Col>

      <Col lg={6}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        
      <Form.Control type="number" placeholder="Phone" value={phone} name="phone" onChange={changehandler} />
       
      </Form.Group>
      </Col>
      <Col lg={6}>
      <Form.Group className="mb-3" controlId="formBasicPassword">
      <Form.Control type="text" placeholder="Subject" value={subject} name="subject" onChange={changehandler} />

      </Form.Group>
      </Col>

      </Row>
      <Form.Group className="mb-3 " controlId="formBasicPassword" >
       
        <Form.Control type="text" placeholder="Message" style={{ height: '100px' }} value={message} name="message" onChange={changehandler} className='message-form' />
      </Form.Group>

      <Button variant="primary" onClick={formdatasubmit}  style={{width:'100%'}}>
        Submit
      </Button>
    </Form>
  
        </Col>
        <Col lg={6}  xs={12} md={12} className='contcat-map '>
          <div className='contact-map-div'>
                      <div >
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6982.8026750461995!2d58.48805748211582!3d23.609354132915556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e91f9005391431d%3A0x3cd9f3e3eaca38b4!2sBahwan%20Services%20and%20Trading%20LLC!5e0!3m2!1sen!2sin!4v1707994121053!5m2!1sen!2sin" width="100%" height="300" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.3020387181937!2d78.37538891390291!3d17.44525190572766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9379fcd04339%3A0x774a3ffbe194144!2sBSTL%20Offshore%20Consultants%20Private%20Limited!5e0!3m2!1sen!2sin!4v1675840884317!5m2!1sen!2sin" width="100%" height="300px" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
                       </div> 
          </div>
        </Col>
      </Row>
    </Container>   
    </>
  )
}

export default Contact
