import React from 'react'
import { Col,Row, Container,Nav, Button } from 'react-bootstrap'
import { Link, useNavigate} from 'react-router-dom'
import { FaCaretRight } from "react-icons/fa";


function Data() {
  const uae = useNavigate()
  const ksa = useNavigate()
  const qatar = useNavigate()
  const india =useNavigate()
  const oman =useNavigate()
  const viewall = useNavigate()

  return (
    <>
      <Container fluid className='international-page'>
          <Nav  >
           
          <Col lg={1} sm={4} xs={6} md={2}  >
            <Nav.Item style={{padding:'0px'}} className='view-all-btn'>
         <Nav.Link>
              <Button onClick={(e)=>viewall('/internationals')} className='international-btn' >View All</Button>
              </Nav.Link>
              </Nav.Item>
            </Col>
            <Col  lg={1} sm={4} xs={6}md={2}>
            <Nav.Item>
             <Nav.Link onClick={(e)=>india('/oman')}><img src="/Asets/MicrosoftTeams-image (41).png" /><p >OMAN</p></Nav.Link>
            </Nav.Item>
            
            </Col>
         
            <Col  lg={1} sm={4} xs={6}md={2}>
            <Nav.Item>
              <Nav.Link onClick={(e)=>uae('/usa')}  ><img src='Asets/uae_falg.jpg'/> <p >UAE</p></Nav.Link>
            </Nav.Item>
            </Col>
            <Col  lg={1} sm={6} xs={6}md={2}>
            <Nav.Item>
              <Nav.Link onClick={(e)=>ksa('/ksa')}><img src='Asets/saudi_flag.jpg'/><p>KSA</p></Nav.Link>
            </Nav.Item>
            </Col>
            <Col  lg={1} sm={6} xs={6}md={2}>
            <Nav.Item>
            <Nav.Link onClick={(e)=>qatar('/qatar')}><img src='Asets/quatar_flag.jpg' className='qatar-img' /> <p >QATAR</p></Nav.Link>
            </Nav.Item>
            </Col>
           
            <Col  lg={1} sm={6} xs={6}md={2}>
            <Nav.Item>
             <Nav.Link onClick={(e)=>india('/india')}><img src='Asets/india_flag.jpeg' /><p >INDIA</p></Nav.Link>
            </Nav.Item>
            
            </Col>
          </Nav>
          <hr style={{width:'93.4%',position:'relative',left:'3%'}}></hr>
          <Row >
      <Col lg={6} md={6}  >
       <div>
       {/* <p><Link to="/page/abb_llc">ABB LLC </Link></p>
       <p><Link to="/page/apollo">Apollo Hospital Muscat LLC</Link></p>
       <p><Link to="/page/super">Apollo Super Speciality Hospital LLC</Link></p>
       <p><Link to="/page/retail">Ash Amaira Retail Private Limited</Link></p>
       <p><Link to="/page/accessories" >Ash Amaira Accessories Trading LLC</Link></p>
       <p><Link to="/page/lamnalco">Bahwan  Lamnalco SAOC</Link></p>
       <p><Link  to="/page/bahwan">Bahwan Renewable Energy Company  LLC  </Link></p>
       <p><Link to="/page/care">Care Plus LLC</Link></p>
       <p> <Link  to="/page/cantiere">Cantiere Navale Vittoria</Link></p>
       <p><Link to="/page/ejada">Ejada International Projects LLC</Link></p>
       <p><Link to="/page/general">General Dynamics Mission Systems-Gulf LLC</Link></p>
       <p> <Link to="/page/gulf">Gulf Security Services LLC</Link></p>
       <p><Link to="/page/green">Green Tech Energy & Watter LLC  </Link></p>
       <p><Link to="/page/homeland">Homeland</Link></p> */}



     <p>
                  <Link to="/page/abb_llc"><FaCaretRight color='blue'/> ABB LLC </Link>
             </p>
              <p>
                  <Link to="/page/hitach"><FaCaretRight color='blue'/> Hitachi Energy LLC</Link>
             </p>
              <p>
                  <Link to="/page/hyunet"><FaCaretRight color='blue'/> Hyunet Private Ltd </Link>
             </p>
              <p>
                  <Link to="/page/green"><FaCaretRight color='blue'/> Green Tech Energy & Watter LLC  </Link>
             </p>
          <p>
                  <Link to="/page/homeland"><FaCaretRight color='blue'/> Homeland Plants LLC </Link>
             </p>
              <p>
                  <Link to="/page/blue_aqua"><FaCaretRight color='blue'/> Blue Aqua LLC</Link>
             </p>
              <p>
                  <Link to="/page/blue_aqua_singarpur"><FaCaretRight color='blue'/> Blue Aqua, Singapore</Link>
             </p>
              <p>
                  <Link to="/page/apollo_muscat"><FaCaretRight color='blue'/> Apollo Hospital Muscat LLC </Link>
             </p>
              <p>
                  <Link to="/page/international"><FaCaretRight color='blue'/> International Medical Center LLC  </Link>
             </p>
              <p>
                  <Link to="/page/super"><FaCaretRight color='blue'/> Apollo Super Speciality Hospital LLC </Link>
             </p>
              <p>
                  <Link to="/page/amaira_retail"><FaCaretRight color='blue'/> Ash Amaira Retail Private Limited</Link>
             </p>
              <p>
                  <Link to="/page/accessories"><FaCaretRight color='blue'/> Ash Amaira Accessories Trading LLC</Link>
             </p>
              <p>
                  <Link to="/page/genral"><FaCaretRight color='blue'/> General Dynamics Mission Systems-Gulf LLC</Link>
             </p>



  

 
     </div>    
          
       
        </Col>
        <Col lg={6}  md={6}>
      <div >
      {/* <p><Link to="/page/hitach">Hitachi Energy LLC</Link></p>
      <p><Link to="/page/hyunet">Hyunet Private Ltd,India</Link></p>
      <p><Link to="/page/international">International Medical Center LLC</Link></p>
      <p><Link to="/page/ipark">I Park Robotic Parking & Elevators LLC</Link></p>
      <p><Link to="/page/intelligent">Intelligent Parking & Elevators LLC</Link></p>
      <p> <Link to="/page/loconav">Loconav Inc</Link></p>
      <p><Link to="/page/north">North Ocean Industries SPC</Link></p>
      <p><Link to="/page/rosenbauer">Rosenbauer </Link> </p>
      <p><Link to="/page/sadad">Sadad International LLC</Link></p>
      <p><Link to="/page/techno">Techno Elevators LLC</Link></p>
   
      <p><Link to="/page/vanderlande">Vanderlande Industries</Link></p>
      <p><Link to="/page/woodenm">Wooden Pallet Manufacturing</Link></p>    */}


<p>
                  <Link to="/page/gulf"><FaCaretRight color='blue'/> Gulf Security Services LLC</Link>
               </p>
                <p>
                  <Link to="/page/bahwan_lamnalco"><FaCaretRight color='blue'/> Bahwan Lamnalco SAOC</Link>
               </p>
                <p>
                  <Link to="/page/rosenbauer"><FaCaretRight color='blue'/> Rosenbauer MENA FZE (Austria)</Link>
               </p>
                <p>
                  <Link to="/page/cantiere"><FaCaretRight color='blue'/> Cantiere Navale Vittoria S.p.A. (Italy)</Link>
               </p>
                <p>
                  <Link to="/page/vanderlande"><FaCaretRight color='blue'/> Vanderlande Industries B.V. (Netherlands)</Link>
               </p>
                <p>
                  <Link to="/page/loconav"><FaCaretRight color='blue'/> Loconav Inc. (USA)</Link>
               </p>
                <p>
                  <Link to="/page/al_qantar"><FaCaretRight color='blue'/> Al Qantar International SPC</Link>
               </p>
                <p>
                  <Link to="/page/techno"><FaCaretRight color='blue'/> Techno  Elevators LLC</Link>
               </p>
                <p>
                  <Link to="/page/intelligent"><FaCaretRight color='blue'/> Intelligent Parking  & Elevators LLC</Link>
               </p>
                <p>
                  <Link to="/page/ipark"><FaCaretRight color='blue'/> I Park Robotic Parking & Elevators LLC</Link>
               </p>
                <p>
                  <Link to="/page/north"><FaCaretRight color='blue'/> North Ocean Industries SPC</Link>
               </p>
                <p>
                  <Link to="/page/sadad"><FaCaretRight color='blue'/> Sadad International LLC </Link>
               </p>
      </div>
        </Col>
        </Row>
      
         
          </Container>
    </>
  )
}

export default Data
