import React from 'react'
import { Carousel, Container,Row ,Col, Card} from 'react-bootstrap'
import { Helmet } from 'react-helmet';
import { BsEyeFill, BsFillHandbagFill, BsHandIndex, BsHandIndexFill, BsHandThumbsDown } from 'react-icons/bs';
import { FaHands} from "react-icons/fa";
import { FiTarget } from "react-icons/fi";

function Vision() {



  return (
    <>
    <Helmet>
    <title>Vision-value</title>
        <meta name="description" content="Description of your page" />
        <meta name="keywords" content="managebstl" />
        <link rel="canonical" href="http://localhost:3000/management"></link>
    </Helmet>
    <Carousel  indicators={false} controls={false} >
        <Carousel.Item>
            <img  src='Asets/vision.jpg'
                width={'100%'}
                height={'100%'}
            />
        </Carousel.Item>
        <Carousel.Item>
            <img  src='Asets/mission.jpg'
                width={'100%'}
                height={'100%'}/>
        </Carousel.Item>
    </Carousel>
    <Container fluid className='vision-container'>
        <Row className='mt-5 mb-5'>
            <Col lg={4} md={4}>
                <div className='vision-div'> 
                <div className='div-1'>
                  <BsEyeFill className='icon-colors'   />
                  </div>
                  <div>
                <h6 cl>Vision</h6>
                <p>A globally recognized business group creating customers for life</p>
                </div>
                </div> 
            </Col>
            <Col lg={4}  md={4}>
                <div  className='vision-div'>
                  <div className='div-1'>
                  <FiTarget  className='icon-colors' style={{backgroundColor:'#009ADF'}}  />
                  </div>
                  <div>
                <h6>Mission</h6>
                <p>Diversify and innovatively deliver world-class products and services,through empowered employees by creating value for our stakeholders</p>
                </div>
                </div> 
            </Col>
            <Col lg={4}  md={4}>
                <div  className='vision-div'>
                  <div className='div-1'>
                  <FaHands className='icon-colors' style={{backgroundColor:'#89B1C5'}}  />
                  </div>
                  <div>
                <h6 >Values</h6>
              
                <li>Integrity</li>
                <li>Ownership</li>
                <li>Passion</li>
                <li>Excellence</li>
               
                </div>
                </div> 
            </Col>  
        </Row>
    </Container>
      
    </>
  )
}

export default Vision
