import React, { createContext } from 'react';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Header from "./Components/MainPageComponents/Header";
import Homepage from './Components/MainPageComponents/Homepage';
import Navbar from './Components/MainPageComponents/Navbar';
import Contact from "./Components/MainPageComponents/Contact";
import Dynamic_page from './Components/Dyanamic_pages';
import International from "./Components/MainPageComponents/International/International";
import Ksa from './Components/MainPageComponents/International/Ksa';
import Qatar from './Components/MainPageComponents/International/Qatar';
import India from './Components/MainPageComponents/International/India';
import Oman from './Components/MainPageComponents/International/Oman';
import Uae from './Components/MainPageComponents/International/Uae';
import Images from './Components/Media/Images';
import Video from './Components/Media/Video';
import ContactBanner from './Components/MainPageComponents/ContactBanner';
import Footer from './Components/MainPageComponents/Footer';
import News from './Components/MainPageComponents/News';
import SingleNews from './Components/MainPageComponents/SingleNews';
import Management from './Components/MainPageComponents/Management';
import Vision from './Components/MainPageComponents/Vision';
import Staticpages from './Components/Staticpages';


import "./index.css"
import BasicExamples from './Components/MainPageComponents/Navbar copy';

function App() {
 

  

  return (
    <>
    
    <BrowserRouter >
    <Header/>
    <Navbar/>
    <Routes>
        <Route exact path='/'  element={<Homepage/>}/>
        <Route exact path="/contact" element={<Contact/>}/>
        <Route path="/page/:pagename" element={<Dynamic_page/>}/>
        <Route path='/internationals'  element={<International/>}/>
        <Route path='/india'           element={<India/>}/>
        <Route path='/oman'            element={<Oman/>}/>
        <Route path='/qatar'            element={<Qatar/>}/>
        <Route path='ksa'               element={<Ksa/>}/>
        <Route path='usa'               element={<Uae/>}/>
        <Route path="images"            element={<Images/>}/>
        <Route path="video"             element={<Video/>}/>
        <Route path="news" element={<News/>}/>
        <Route path="news/:id" element={<SingleNews/>}/>
        <Route path='management'        element={< Management/>}/>
        <Route path='vision'             element={<Vision/>}/>
        <Route path="tems_and_conditions"             element={<Staticpages/>}/>
        <Route path="privacy_policy"             element={<Staticpages/>}/>
       
    </Routes>
     <Footer/>
    </BrowserRouter>
      
    </>
  )
}

export default App
