// import axios from 'axios'
// import React, { useEffect, useState } from 'react'
// import { Carousel, Tabs, Tab, Col, Row, Container } from 'react-bootstrap'
// import Modal from 'react-bootstrap/Modal';
// import { Search } from 'react-bootstrap-icons';

// function Images() {

//     const [category, setCategory] = useState()
//     const [imagesAll, setImagesAll] = useState()
//     const [show, setShow] = useState(false);
//     const [activeItem, setActiveItem] = useState(1);
//     const handleClose = () => setShow(false);

//     const handleShow = (id) => {
//         console.log("clicked")
//         setShow(true)
//         setActiveItem(id)
//     };



//     useEffect(() => {
//         getImages("All")
//         axios.get('https://admin.bstlglobal.com/api/v1/categories_list')
//             .then(res => setCategory(res.data))
//     }, [0])

//     const getImages = ((cat) => {
//         console.log("function called")
//         axios.get('https://admin.bstlglobal.com/api/v1/media_by_images/' + cat)
//             .then(res => setImagesAll(res.data))
//     })


//     return (
//         <>
//             <Container fluid>
//                 <Row className='Feaild'>
//                     <Col lg={2} md={3} xs={12} sm={12} onClick={() => getImages('All')}>
//                         Show All
//                     </Col>
//                     {category &&
//                         category.map((element, index) => {
//                             return (

//                                 <Col lg={2} md={3} xs={12} sm={12} onClick={() => getImages(element.name)}>
//                                     {element.name}
//                                 </Col>

//                             )
//                         })
//                     }
//                 </Row>

//                 <Row className='images-row mb-5'>
//                     {
//                         imagesAll && imagesAll.map((im, index) => {
//                             return (
//                                 <Col lg={3}>
//                                     <img src={"https://admin.bstlglobal.com/" + im.image} onClick={() => handleShow(im.id)} />
//                                 </Col>
//                             )
//                         })

//                     }
//                 </Row>




//                 <Modal show={show} onHide={handleClose} closeButton >
//                     <Modal.Body width="100%">
                       
                            
                              
//                                         <div>

//                                         <iframe width="100%" height="100%" src="https://www.youtube.com/embed/qHIKRwYe3Cs" title="How Rice is Made : Step by Step Growing Rice Paddy Farming, South India" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

//                                         </div>


                          
                       
//                     </Modal.Body>



//                 </Modal>



//             </Container>
//         </>
//     )
// }

// export default Images

import React from 'react'

function Video() {
  return (
    <div>
      <h3 className='mt-5 mb-5 text-center'>NO VIDEOS</h3>
    </div>
  )
}

export default Video

