import React, { useEffect } from 'react'
import { Carousel, Container, Row, Col } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { FaAngleRight } from "react-icons/fa6";
import { NodePlusFill } from 'react-bootstrap-icons';

function Dyanamic_pages() {

  const insidedata = [
    {name: "/page/techno",
      banners: [
        { image: "/Asets/inside_banner/escalator-with-orange-steps-shiny-metal-sides_1280x450.jpg" },
        { image: "/Asets/inside_banner/low-angle-view-escalator_1280x450.jpg" },
        { image: "/Asets/inside_banner/modern-steel-elevatore-3d-rendering-image_1280x450.jpg" }
      ],
      heading: "Techno Elevator",
      content: "Techno elevators with a team of specialist and expert engineers, we provide services for all types of commercial and residential elevators focusing on superior quality and affordability. Techno Elevator offers its customers a variety of customized solution with a diverse range of products based on elevators usage and building design. Techno Elevator’s products offer unique ride comfort, outstanding safety performance and efficient use of space during construction and maximize the value of the building with their sophisticated design and convenience features.",
      links: [
        { name: "Intelligent Parking  & Elevators Co. LLC", url: "/page/ABB_LLC" },
        { name: "I PARK ROBOTIC PARKING and  ELEVATORS L.L.C", url: "/page/hitachi" },
        { name: "North Ocean Industries SPC", url: "/page/hyunet" },


      ],
      subimage: [
        { image: "/Asets/inside_banner/mother-child-together-escalator-background-terminal-airport-travel-357x200.jpg" }
      ]
    },
    {name: "/page/financial",
      banners: [
        { image: "https://admin.bstlglobal.com/storage/uploads/mlEbr7BAHtAq9MMt3jAktzMjiUMs2BccnCVWIMmV.jpg" },
        { image: "https://admin.bstlglobal.com/storage/uploads/UX1J6FJHpel3LsTZkxR3SxwHe32Ylkn3g1ZR62uo.jpg" }
      ],
      heading: "Financial Services",
      content: "Financial consultancy, risk assessment and mitigation, billing and bill collection services are provided across the industries.",
      links: [
        { name: "Sadad International LLC", url: "/page/sadad" }

      ]
    },
    {name: "/page/sadad",
      banners: [
        { image: "/Asets/inside_banner/sadad/business-people-together-communication-concept_1280x450.jpg" },
      ],
      heading: "Sadad International LLC",
      content: "SADAD is a full-service debt collection company based in Oman. We are experts in the areas of financial debt collection (whether payment due, overdue or bad debt). We have a dedicated team of debt recovery and debt collection experts with years of experience. Our clients include individuals, SMEs, corporations, and semi-government companies. We also work closely with government agencies to provide more efficient debt collection processes.",
      content2: "Our expertise in Debt collection/Debt recovery has enabled us to achieve tremendous success. Our experts will assist with case investigation, finding the right individuals, consistent follow-ups, and even legal enforcement. We have a call center, collection team, and an in-house legal team who can help with legal consultancy in recovering dues. Our professionals have a combined experience of over 40 years in the field of Credit, Collections, and Receivables Management. SADAD is committed to delivering the highest standards in client care which is why we invest in our professionals to ensure they carry out their responsibilities in a professional manner. We undertake all the burdens and responsibilities of debt collection at a very reasonable service fee.",
      content3: "We are functioning in the areas of collection of financial debt whether payment due, overdue or bad debt. We have a dedicated team of Debt Recovery and Debt collection experts who have years of experience in the collection of Debts. Our clients include Individuals, SME’s, Corporates and semi-government Companies. ",
      content4: "We also work very closely with government agencies to bring efficiency to our debt collection processes. Our expertise in Debt collection/Debt recovery has enabled us to achieve tremendous success.",
      content5: "Our experts will help you with case investigation, finding the right individuals, consistent follow-ups, and even legal enforcements. We have a call center, collection team and an in-house legal team who can help with legal consultancy in recovering dues.",


      subimage: [
        { image: "/Asets/inside_banner/sadad/Debt-Recovery_357x200.jpg" },
        { image: "/Asets/inside_banner/sadad/brown-gavel_357x200.jpg" }
      ]

    },
    {name: "/page/food",
      banners: [
        { image: "https://admin.bstlglobal.com/storage/uploads/sz0nleTMLWw8pC8p1bqCJW0XXepINaS364ubPefn.png" },
        { image: "https://admin.bstlglobal.com/storage/uploads/3SNeMt5tqvCDJ4Kr6uJobr5bpJs6E0wlou1sVZ6B.png" }

      ],
      heading: 'Food',
      content: "World population is touching 8 billion, and according to the Global Report on Food Crisis 2022 Mid-year Update, up to 205 million people are expected to face acute food insecurity.",
      content2: "As per Food & Agriculture Organization of the United Nations (FAO) healthy diets are out of reach for about 3 billion people. World had realized importance of healthy & complete diet due to the COVID-19 pandemic.",
      content3: "Shifting to healthy diets that include sustainability considerations can contribute to reducing health and climate change costs by 2030, because the hidden costs of these diets are lower compared with those of current consumption patterns.",
      content4: "Most of the GCC countries are dependent on imported food due to challenging local climatic conditions. Oman annually imports food items worth more than US$ 5 billion.",
      content5: "In Oman’s vision 2030 food security is on top of the chart and BST is contributing in it by bringing the most sustainable format of agriculture to GCC – Soilless, pesticide & chemical free Aquaponics technology (a sustainable methodology in which simultaneous raring of fish and growing of vegetables is done with very less water requirement).",
      links: [
        { name: "Homeland", url: "/page/homeland" },



      ],
      subimage: [
        { image: "https://media.istockphoto.com/id/1203599923/photo/food-background-with-assortment-of-fresh-organic-vegetables.jpg?s=612x612&w=0&k=20&c=DZy1JMfUBkllwiq1Fm_LXtxA4DMDnExuF40jD8u9Z0Q=" },
      ]
    },
    {name: "/page/homeland",
      banners: [
        { image: "/Asets/inside_banner/row-green-red-lettuce_1280x450.jpg" },
        { image: "/Asets/inside_banner/industrial-greenhouse-interior-hydroponic-indoor-vegetable-plant-factory-green-salad-farm-concrete-floor-3d-render_1280x450.jpg" },
        { image: "/Asets/inside_banner/midsection-person-holding-leaf-field_1280x450.jpg" }

      ],
      heading: 'Homeland Plants LLC',
     content6:<>
     <p>Welcome to Homeland Plants LLC (HLPL), an Omani limited liability company, working in food sector, specializing in sustainable commercial aquaculture & farming practices.  Our vision is to locally produce high-quality, nutrient-dense, and environmentally friendly food for the communities while also contributing to Oman’s food security. </p>
     <p>At Homeland Plants LLC, we are committed to playing a key role in achieving food security targets in Oman. Our approach to food production focuses on increasing local food production, reducing dependence on imports, and promoting sustainable farming practices that conserve natural resources and protect the environment. We are using advanced technologies like Aquaponics, Sea-caged farming, Hi-Tech Greenhouse farming to maximize yields while minimizing water usage and energy consumption.</p>
     <p>We also prioritize food education and awareness, helping consumers make informed decisions about their food choices. By fostering a deeper understanding of the importance of sustainable and locally grown food, we can work together to create a more resilient and food-secure Oman.

</p>

<div>
<h6 >Contact </h6>
     <p>P.O. Box 253, P.C. 118, Muscat, Sultanate of Oman.<br></br>
Phone:  +968 2456 0081, Telefax: +968 2456 0713
</p>
<p style={{marginBottom:'0px'}}>Mr. Moiz Durrany, Project Manager</p>
<p>Email : <a href="moiz@sbigh.com" target="_blank">moiz@sbigh.com</a></p>
<p>To learn more about HLPL, visit : &nbsp;<a href="https://www.evergreenharvest.com/" target="_blank">www.evergreenharvest.com</a></p>
</div>
     </>,
      links: [
        { name: "", url: "/page/homeland" },



      ],
      subimage: [
        { image: "/Asets/inside_banner/shimmering-barracudas-swarm-en-masse-deep-blue-sea_357x200.jpg" },
        { image: "/Asets/MicrosoftTeams-image (35).png" }
      ]
    },
    {name: "/page/genral",
      banners: [
        { image: "/Asets/inside_banner/construction-viaduct-with-cranes_1280x450.jpg" },
        { image: "/Asets/inside_banner/illuminated-factory-against-sky-sunset_1280x450.jpg" },
        { image: "/Asets/inside_banner/oil-refinery-terminal-terminal-tankers-transporting-oil-north-sea_1280x450.jpg" },
        { image: "/Asets/inside_banner/plants-against-blue-sky_1280x450.jpg" },


      ],
      heading: 'General Dynamics Mission Systems',
      content: "General Dynamics Mission Systems provides mission critical solutions to those who lead, serve and protect the world we live in. We are a technology integrator and original equipment manufacturer with deep domain expertise in land, sea, air, space and cyber.",
      content2: "General Dynamics Mission Systems announced today that its TACLANE-C175N CHVP encryptor has been certified for use by the National Security Agency (NSA).",
      content3: "General Dynamics built the S-Band Transponders and emergency radios for the Orion spacecraft. When Orion’s crew makes its first trip to space, our transponders will keep the astronauts connected to mission command centers on Earth ",
      content4: "General Dynamics announced it was awarded a broad scope of work for the Columbia and Dreadnought ballistic missile submarine classes to support development, production and installation of fire control systems.",
      content5: "",
      links: [
        { name: "Gulf Security Services LLC", url: "/page/homeland" },
        { name: "Bahwan Lamnalco SAOC", url: "/page/homeland" },



      ],
      subimage: [
        { image: "/Asets/inside_banner/aerial-view-oil-gas-tank-with-oil-refinery-background-night-glitter-lighting-petrochemical_357x300.jpg" },
      ]
    },
    {name: "/page/abb_llc",
      banners: [
       
        { image: "/Asets/ABB/ABB/MicrosoftTeams-image (33).png" },
        { image: "/Asets/inside_banner/closeup-view-cylindrical-grinder-industrial-concept_1280x450.jpg" },
        { image: "/Asets/inside_banner/gas-production-rigs-gas-production-processing-transportation-view-from-drone_1280x450.jpg" },
        { image: "/Asets/inside_banner/oil-platform-ocean-offshore-drilling-gas-petroleum-supply-vessel-alongside-industrial_1280x450.jpg" },
        { image: "/Asets/inside_banner/powerful-crawler-excavator-loads-earth-into-dump-truck-against-blue-sky-development-removal-soil-from-construction-site_1280x450.jpg" }
      ],
      heading: "ABB LLC",
      // content: `ABB is a technology leader in electrification and automation, enabling a more sustainable and resource-efficient future. The company’s solutions connect engineering know-how and software to optimize how things are manufactured, moved, powered and operated. Building on more than 130 years of excellence, ABB’s 105,000 employees are committed to driving innovations that accelerate industrial transformation.`,
      // content2: "Electrification, Motion, Process Automation and Robotics & Discrete Automation are our four business areas and the 20 divisions they comprise push the boundaries of technology, driving innovation and value for customers every day.   ",
      // content3: "ABB is committed to solving some of the biggest global challenges of our time. This is only made possible through our exceptional people working every day in this endeavor. At ABB, we empower people with technology to drive the transformation of industries needed to enable a low-carbon society and address the world’s energy challenges. Our culture of diversity, inclusion and equal opportunity makes us stronger and is the key to our success.",
      // content4: "At ABB, we’re passionate about creating success — together. We’re working to drive social progress alongside technological innovation, empowering our employees, partners and customers to envision new technology frontiers and make positive change a reality.",
      content6: <> 
      <p>ABB is building on more than its 140 years of excellence in whatever it does.  ABB is committed to driving innovations that accelerate industrial transformation in global markets.</p>
     
     <p>BST is a Joint Venture partner with ABB for its Oman operations since inception. This JV was one of the leading the organization who was instrumental in building of Oman’s current power generation, transmission, and distribution portfolio of about 9,000 MW in Sultanate of Oman. ABB is a technology leader in electrification and automation, enabling a more sustainable and resource-efficient future. The company’s solutions connect engineering know-how and software to optimize how things are manufactured, moved, powered, and operated. </p>
      
      <p>ABB had divested its HV business to Hitachi. ABB’s current operations are organized into four global business areas, mainly focused on following : </p>
     
     <ul>
     <li className='mb-2'>Electrification : ABB's Electrification business offers a wide-ranging portfolio of products, digital solutions and services, from substation to socket, enabling safe, smart and sustainable electrification</li>
     
     <li className='mb-2'>Motion : ABB's Motion business is the largest supplier of drives and motors, globally. ABB provide customers with the complete range of electrical motors, generators, drives and services, as well as integrated digital powertrain solutions.</li>
     
     <li className='mb-2'>Process Automation : ABB's Process Automation business offers a broad range of solutions for process and hybrid industries, including industry-specific integrated automation, electrification and digital solutions, control technologies, software and advanced services, as well as measurement & analytics, and marine offerings.</li>
     
     <li >Robotics & Discrete Automation : ABB's Robotics & Discrete Automation business provides value-added solutions in robotics, machine and factory automation.</li>
     </ul>
     <div>
     <h6 >Contact </h6>
     <p>305 Hatat Complex B, <br></br>
P.O. Box 778, P.Code 131, Al Hamriya, Muscat, Sultanate of Oman.<br></br>
Phone:  +968 2466 6500, Fax: +968 2466 6590</p>
<p style={{marginBottom:'0px'}}>Ms. Sana Saad, Country Head</p>
<p>Email : <a href="sana.saad@om.abb.com" target="_blank">sana.saad@om.abb.com</a></p>
<p>To learn more about ABB, visit : &nbsp;<a href="https://global.abb/group/en.com" target="_blank">www.abb.com</a></p>
</div>
     </>,
      links: [
        { name: "Hitachi Energy LLC", url: "/page/hitach" },
        { name: "Hyunet Private Ltd", url: "/page/hyunet" },
        { name: "Green Tech Energy & Water LLC", url: "/page/green" },


      ],
      subimage: [
        { image: "/Asets/ABB/ABB/MicrosoftTeams-image (32).png" },
        { image: "/Asets/ABB/ABB/MicrosoftTeams-image (31).png" },
        { image: "/Asets/ABB/ABB/MicrosoftTeams-image (30).png" },
        { image: "/Asets/ABB/ABB/MicrosoftTeams-image (29).png" },



      ]
    },
    {name: "/page/health",
      banners: [
        { image: "https://admin.bstlglobal.com/storage/uploads/bJHMeXqlb9ElgiiZQ4geaW0aWPqK97lB62qxhqpK.png" },

      ],
      heading: 'Healthcare',
      content: "During COVID-19 pandemic the entire world has realized that primary defense of any country is its capable healthcare sector. Healthcare is the improvement of health via the prevention, diagnosis, treatment, amelioration or cure of disease, illness, injury, and other physical and mental impairments in people. BST through its sister concern Heath Spring LLC has formed a joint venture with Apollo Hospitals Muscat to offer affordable and advanced healthcare services to Omani Nationals and Residents through its network of hospitals, clinics, and pharmacies.",
      content2: "Apollo Hospitals Muscat provides primary, secondary and tertiary care services along with home and community care.",
      links: [
        { name: "Apollo Hospital Muscat LLC", url: "/page/apollo" },
        { name: "International Medical Center LLC", url: "/page/international" },
        { name: "Apollo Super Speciality Hospital LLC", url: "/page/super" },




      ],
      subimage: [
        { image: "https://media.istockphoto.com/id/1327568875/photo/healthcare-business-graph-data-and-growth-insurance-healthcare-doctor-analyzing-medical-of.jpg?s=612x612&w=0&k=20&c=R4idIeTPq0f1TPSJwAq4KUeLUQg6ul8eIBSjvs9MXQk=" },
      ]
    },
    {name: "/page/hitach",
      banners: [
        { image: "/Asets/hitachi.png" },

      ],
      heading: 'Hitachi Energy LLC ',
      // content: "Our business provides pathways to accelerate the energy transition and deliver customer success. We are a global leader in power technologies and energy systems. Customers across the utilities, transportation, IT such as data centers, industry and Smart Life sectors trust our world-class teams of experts to deliver pioneering and digital solutions across the power value chain. Our solutions help meet the growing demand for electricity – affordably, reliably, sustainably and in a modern way.",
      // content2: "Customers rely upon our solutions to across the value chain. We help plan, build, operate, optimize and maintain their power infrastructure, whilst increasing overall flexibility and resilience. Our pioneering technologies and services enable safe and efficient integration, transmission and distribution of bulk and distributed energy generated from conventional and renewable sources.  ",
      // content3: "Four global Business Units and an extensive local office network To deliver maximum value, we have organized our operations around four global Business Units and have local offices and research centers spanning 90 countries. This highly networked design enables our 40,000 experts to stay close to customers, fostering a strong awareness and knowledge of local market operating conditions. We connect the latest pioneering developments and solutions through the competence and reference points of our global Business Units.",
      // content4: "Our Grid Automation business unit has a comprehensive portfolio of solutions. These range from substation automation, communication networks, grid automation services, grid edge solutions to enterprise software solutions. These solutions address all key segments connected to the energy system including generation, transmission distribution, industries, transportation and infrastructure sectors. ",
      // content5: "Hitachi Energy's Grid Automation business unit supports 50% of the top 250 global electric utilities. Our Grid Automation hardware, software and services portfolio unites deep domain knowledge and innovative technologies that enable customers across the globe to optimize the critical systems that power, move and connect us. Together, we’re building a more productive and sustainable world.  ",
      content6:<>
      <p>BST is a Joint Venture partner with Hitachi Energy for its Oman operations. This JV was one of the leading the organization who is working towards pioneering technologies and enable the digital transformation required to accelerate the energy transition towards a carbon-neutral future of Sultanate of Oman.</p>

<p>In 2020, Hitachi and ABB's Power Grids' business came together in a joint venture to create a new global leader in pioneering and digital technologies. As individual companies, both had been innovating and improving technology for over a century. Together, as Hitachi Energy, it brings forward and builds on this remarkable heritage to tackle today's challenges facing business and society. Trusted as a partner of choice to the industry, we co-create best-in-class solutions that deliver on the promise of a sustainable energy future.</p>

<p>Hitachi Energy, headquartered in Switzerland, is a global technology leader that is advancing a sustainable energy future for all.</p>

<p>Hitachi Energy serve customers in the utility, industry, and infrastructure sectors with innovative solutions and services across the value chain.</p>

<p>We are advancing the world's energy system to become more sustainable, flexible, and secure while balancing social, environmental, and economic value. Hitachi Energy has a proven track record and unparalleled installed base in more than 140 countries, and integrate more than 150 GW of HVDC links into the power system, helping our customers enable more wind and solar.</p>

<p>Discover more about Hitachi Energy's global Business Units:</p>

<ol>
  <li>
    <h6>Grid Automation</h6>
    <p>Hitachi Energy's Grid Automation business unit has a comprehensive portfolio of solutions. These range from substation automation, communication networks, grid automation services, grid edge solutions to enterprise software solutions. These solutions address all key segments connected to the energy system including generation, transmission distribution, industries, transportation and infrastructure sectors.</p>
    <p>Hitachi Energy's Grid Automation business unit supports 50% of the top 250 global electric utilities. Our Grid Automation hardware, software and services portfolio unites deep domain knowledge and innovative technologies that enable customers across the globe to optimize the critical systems that power, move, and connect us. Together, we’re building a more productive and sustainable world.</p>
  </li>
  <li>
    <h6>Grid Integration</h6>
    <p>Hitachi Energy's Grid Integration business unit has delivered more than 4,000 projects over the last 15 years. Our Grid Integration portfolio spans a wide range of transmission and substation applications, which facilitate reliable and efficient system integration of the future digital electric network with minimum environmental impact.</p>
    <p>We incorporate the integrated systems, solutions and services of our business' DC and AC fields, including HVDC, Substations, FACTS, Grid and Power Quality solutions such as offshore wind connections, and Semiconductors. We offer Power Consulting for key segments in the energy system, such as renewables, transmission distribution and as well as e-transportation solutions.</p>
  </li>
</ol>
<div class="bracket-box">
  <div class="left-bracket"></div>
  <div>
   <h6>Contact </h6>
   <p>304, Hatat Complex B, <br></br>
P.O. Box 1459, P.Code 131, Al Hamriya, Muscat, Sultanate of Oman.<br></br>
Phone:  +968 2466 6570, Telefax: +968 2466 6590

</p>
<p style={{marginBottom:'0px'}}>Mr. Ammar Naji, Country Head</p>
<p>Email : <a href="sana.saad@om.abb.com" target="_blank">Ammar.Naji@hitachienergy.com</a></p>
<p style={{marginBottom:'0px'}}>To learn more about Hitachi, visit  : &nbsp;<a href="https://www.hitachienergy.com/me/en" target="_blank">www.hitachienergy.com</a></p>
</div>
  <div class="right-bracket"></div>
</div>

      </>,
      links: [
        { name: "ABB LLC", url: "/page/abb_llc" },
        { name: "Hyunet Private Ltd", url: "/page/hyunat" },
        { name: "Green Tech Energy & Watter LLC", url: "/page/green" },




      ],
      subimage: [
        { image: "https://dynamic-assets.hitachienergy.com/is/image/hitachiabbpowergrids/women-in-2023-header:3-2?wid=960&hei=640&fmt=webp-alpha&fit=crop%2C1" },
        { image: "https://dynamic-assets.hitachienergy.com/is/image/hitachiabbpowergrids/dsc09476:64-25?wid=2880&hei=1125&fmt=webp-alpha&fit=crop%2C1" }
      ]
    },
    {name: "/page/hyunet",
      banners: [
        { image: "/Asets/hitachi.png" },

      ],
      heading: 'Hyunet Private Ltd',
      content: "Hyunet Private Limited (HPL) is a privately held joint venture company established by BSTL-Oman and Juniten Pte. Ltd-Singapore.",
      content2: "HPL is in the process of setting up of 36 GWh Advanced Chemistry Cell manufacturing project in Karnataka-India. Currently, the company is executing 6 GWh capacity (Phase -1) of the project. HPL had signed technology and licensing agreement with ThunderVolt Technologies Pte. Ltd for sourcing state-of-the-art prismatic and pouch cell batteries suitable for e-Mobility and Battery Energy Storage applications. Raw material supply chain is established through an aggregator to supply all required Bill Of Quantities (BOQ) for first phase. Plant and Machinery are ordered to globally reputed manufactures and currently under production.",
      content3: "HPL is in discussions with various 2W-EVs, 3W-EVs, Cars and Buses for supplying its cells. HPL is also looking for opportunities to provide its cells for Stationary Battery Energy Storage applications.",
      content4: "Electrifying transportation and increasing renewable grid penetration are key goals of the Indian government, which will require a significant investment in domestically produced lithium-ion batteries (LIB) factories. Global electric vehicle (EV) sales reached 10.5 million units in 2022, up 60 % compared to 2021. Forecasters expect EV sales to reach around 35 million units in 2030, higher if remaining issues in supply and logistics are resolved.",
      content5: "Sustainability is the foundation block on which the battery ecosystem will be built upon, as global battery regulations become stricter in order to minimize the carbon footprint and increase safety; many conventional battery manufactures will struggle to remain compliant. In view of these challenges, Hyunet’s clean low-cost batteries and energy storage systems are uniquely positioned to comply with future battery regulations.",

      links: [
        { name: "ABB LLC", url: "/page/abb_llc" },
        { name: "Hitachi Energy LLC", url: "/page/hitach" },
        { name: "Green Tech Energy & Watter LLC", url: "/page/green" },




      ],
      subimage: [
        { image: "https://admin.bstlglobal.com/storage/uploads/djZvbTW0dvEmONMCjUpPiDuqoPS3eGTheMFWbXYt.jpg" },
      ]
    },
    {name: "/page/al_qantar",
      banners: [
        { image: "/Asets/inside_banner/stack-wooden-pallets-storage-warehouse_1280x450.jpg" },
        { image: "/Asets/inside_banner/warehouse-interior-pallets-with-goods-open-air-transportation-logistic_1280x450.jpg" },


      ],
      heading: 'Al Qantar International spc',
      content: 'Al Qantar International L.L.C  is first fully automatic Wooden Pallet production factory in Sultanate of Oman. Our Wooden Pallet plant is fully automatic, computerized plant. We make 4000 Wooden Pallet daily. We make all type of Wooden Pallets like as PRS 7, PRS 4 Wooden Pallet, Heavy Industrial Wooden Pallets, EPAL Wooden Pallets called also euro pallets, Notching Wooden Pallets, Wooden Pallet for Ingot, and many other special Wooden Pallets and Skids. Our all Wooden Pallets are fumigated, heat treated as per ISPM15 and approved for export.',
      content2: 'Al Qantar International L.L.C having fully automatic Fumigation plant called also Heat Treatment Plant as per ISPM 15 stander. Our Heat Treatment plant is approved by Oman government. We do heat treatment for our Wooden Pallets for our customer, we also give service to do heat treatment for our partners Wooden Pallets companies. We issue computerized certificate, automatic computerized report for our heat treated Wooden Pallets for export purpose.',
      content3: 'Al Qantar International L.LC is 100% Omani company and situated in Sohar Industrial Esate',
      content4: '',
      content5: '',

      //  links:[
      //   {name:"ABB LLC",url:"/page/abb_llc"},
      //   {name:"Hitachi Energy LLC",url:"/page/hitach"},
      //   {name:"Green Tech Energy & Watter LLC",url:"/page/green"},




      // ],
      subimage: [
        { image: "/Asets/inside_banner/used-wooden-pallet-stacks-357x200.jpg" },

      ]
    },
    {name: "/page/blue_aqua",
      banners: [
        { image: "/Asets/inside_banner/farm-salmon-fishing-norway-aerial-fpv-drone-photography_1280x450.jpg" },
        { image: "/Asets/inside_banner/growing-fish-open-sea-view-ponds-with-fish_1280x450.jpg" },

      ],
      heading: 'Blue Aqua LLC',
      content: "Blue Aqua LLC is a joint venture between Bahwan Services Trading LLC and Blue Aqua – Singapore and it is one of the largest solution provider for the shrimps production in the aquaculture industry.",
      content2: "In 2009, Blue Aqua was founded by Dr Farshad Shishehchian and Ms Amornrat Boonchuay to become a one-stop solution provider for the aquaculture industry. For over a decade, Blue Aqua have supported communities with ground-breaking aquaculture solutions and knowledge by prioritise the role in transforming lives one person, one farm, and one community at a time.",
      content3: "Blue Aqua mission is supported and scaled by six Blue Aqua brands and a Centre of Excellence for the shrimp industry and constantly inventing new solutions to improve the future of aquaculture. The current research and development initiatives include, super-intensive aquafeed development, Al-enabled farm tech solution and research on waste stream valorisation for sustainable farming.",
      content4: "Blue Aqua is committed to innovating towards a brighter future for all. At Blue Aqua, we believe in the symbiosis of the earth and humankind, where food is produced with no discount to the conservation of nature. We strive to harness the necessary optimism and innovation to cultivate a sustainable new world.",


      links: [
        { name: "Homeland", url: "/page/homeland" },

        { name: "Blue Aqua, Singapore", url: "/page/blue_aqua_singarpur" },





      ],
      subimage: [
        { image: "/Asets/inside_banner/scenic-view-sea-against-clear-sky_357x200.jpg" },

      ]
    },
    {name: "/page/blue_aqua_singarpur",
      banners: [
        { image: "/Asets/inside_banner/farm-salmon-fishing-norway-aerial-fpv-drone-photography_1280x450.jpg" },
        { image: "/Asets/inside_banner/growing-fish-open-sea-view-ponds-with-fish_1280x450.jpg" },

      ],
      heading: ' Blue Aqua, Singapore ',
      content2: "In 2009, Blue Aqua was founded by Dr Farshad Shishehchian and Ms Amornrat Boonchuay to become a one-stop solution provider for the aquaculture industry. For over a decade, Blue Aqua have supported communities with ground-breaking aquaculture solutions and knowledge by prioritise the role in transforming lives one person, one farm, and one community at a time.",
      content3: "Blue Aqua mission is supported and scaled by six Blue Aqua brands and a Centre of Excellence for the shrimp industry and constantly inventing new solutions to improve the future of aquaculture. The current research and development initiatives include, super-intensive aquafeed development, Al-enabled farm tech solution and research on waste stream valorisation for sustainable farming.",
      content4: "Blue Aqua is committed to innovating towards a brighter future for all. At Blue Aqua, we believe in the symbiosis of the earth and humankind, where food is produced with no discount to the conservation of nature. We strive to harness the necessary optimism and innovation to cultivate a sustainable new world.",


      links: [
        { name: "Homeland", url: "/page/homeland" },

        { name: "Blue Aqua LLC", url: "/page/blue_aqua" },





      ],
      subimage: [
        { image: "/Asets/inside_banner/scenic-view-sea-against-clear-sky_357x200.jpg" },

      ]
    },
    {name: "/page/green",
      banners: [
        { image: "/Asets/inside_banner/solar-energy_1280x450.jpg" },
        { image: "/Asets/inside_banner/solar-panel-sky-background-_1280x450.jpg" },
        { image: "/Asets/inside_banner/tapping-into-green-power-sources-home-use_1280x450.jpg" },

      ],
      heading: 'GreenTech Energy and Water LLC',

      // content: "GreenTech Energy and Water LLC (GTEW) is founded in the Sultanate of Oman in 2021. GTEW, a joint venture between BSTL and GreenTech Innovation LLC, is the region's premiere partner in renewable energy solutions and sustainable water technologies.",
      // content2: 'GTEW offers European design and engineering capabilities, as well as energy consulting services in the GCC region. We have been instrumental in pioneering grid connected solar PV technology in Oman and aim to become a long-term strategic partner for our customers in their sustainability journey. Our aim is to provide top quality products and services at an affordable price and contribute positively in Oman’s net Zero targets in every way we can.',
      // content3: 'GTEW is desirous of offering comprehensive Renewable Energy power solutions for including but not limited to,',
      // content4: 'Solar power – Roof top, on-ground, utility scale project through single responsibility vendor basis via EPC-M and BOO-M-T business models.',
      content6:<>
      <p>When, one day, if the world runs out of Oil as an energy source Solar and Wind power (renewable energy) are going to be the main energy source for the whole world. Solar Power is one of the keys to a clean energy future and plays a crucial role in the decarbonization of this planet. Every day, the sun gives off far more energy than the whole world needs to power everything on Earth. That is why companies around the world are developing solar plants to energize our homes.</p>

<p>GreenTech Energy and Water LLC (GTEW) was founded in the Sultanate of Oman in 2021. GTEW is a Joint Venture between BST and GreenTech Innovation LLC, and is the region's premiere partner in renewable energy solutions and sustainable water technologies.</p>

<p>GTEW offers best-in-class design and engineering capabilities, as well as energy consulting services in the GCC region. We have been instrumental in pioneering grid-connected solar PV technology in Oman and aim to become a long-term strategic partner for our customers in their sustainability journey. Our aim is to provide top-quality products and services at an affordable price and contribute positively to Oman’s net Zero targets in every way we can!</p>

<p>GTEW is desirous of offering comprehensive Renewable Energy solutions including but not limited to:</p>

<ul>
  <li>Solar power – Roof top, on-ground, utility scale projects through single responsibility vendor basis via EPC-M and BOO-M-T business models.</li>
  <li>Participation in Renewable Energy (RE) tenders for Solar and Wind Power, waste-to-energy (W2E).</li>
  <li>Offering hybrid power solutions for Data centers and telecom towers.</li>
  <li>Stationary and portable Battery Energy Storage Solutions.</li>
  <li>EV car charging solutions.</li>
  <li>Solar hot water generation for Hotels and Residential complexes.</li>
  <li>Offering a total solution for LED lighting with a guaranteed energy savings proposition.</li>
  <li>Drinkable Air – Renewable water, drink water directly from Air.</li>
  <li>Retail/wholesale/distributor for solar products – Ex-stock availability of key components for SMEs.</li>
  <li>Circular economy solutions to convert waste to energy or reusable products.</li>
  <li>Waste and Water treatment solutions.</li>
  <li>Solar power plant Operation & Maintenance solutions to maintain optimum generation levels.</li>
</ul>

<p>We invite you to partner with GTEW in your journey towards harvesting renewable energy, converting waste into reusable products, saving water so that your decarbonization journey is more comfortable and achievable.</p>

      </>,
      links: [
        { name: "ABB LLC", url: "/page/abb_llc" },
        { name: "Hitachi Energy LLC", url: "/page/hitach" },
        { name: "Hyunet Private Ltd", url: "/page/hyunat" },




      ],
      subimage: [
        { image: "/Asets/inside_banner/solar-panel-sky-background_357x200.jpg" },

      ]
    },
    {name: "/page/rosenbauer",
      banners: [
        { image: "/Asets/inside_banner/rosen/csm_Header_Rosenbauer_1280x450.jpg" },
        { image: "/Asets/inside_banner/rosen/rosenbauerjpg_1280x450.jpg" },

      ],
      heading: "Rosenbauer",

      content: "Rosenbauer is the world’s leading manufacturer of systems for firefighting and disaster protection. The company develops and produces vehicles, fire extinguishing systems, equipment and digital solutions for professional, industrial, plant and volunteer fire services, and systems for preventive firefighting. All of the main sets of standards are covered by products manufactured in Europe, the US and Asia. The Group has an active service and sales network in over 120 countries. The listed family company is in its sixth generation and has served fire departments for more than 150 years. A strong customer focus, innovative strength and reliability are Rosenbauer’s key assets. ",
      content2: "Corporate strategy:",
      content3: "As a branch trendsetter, with its innovations and outstanding vehicles, Rosenbauer has exerted a major influence upon the development of fire and disaster protection. Moreover, in years to come, Rosenbauer intends to pursue its claim to being a pioneer with regard to further developments in the firefighting technology field.    ",
      content4: "With its Strategy 2030, Rosenbauer aims to change the world of firefighting. To this end, Rosenbauer is focusing on innovation and technology leadership, customer loyalty, and operational excellence.",

      subimage: [
        { image: "/Asets/inside_banner/rosen/rosenbauer-vehicles-357x200.jpeg" },
        { image: "/Asets/inside_banner/rosen/csm_rosenbauer_news_panther_S_357x200.jpg" },


      ]
    },
    {name: "/page/amaira_retail",
      banners: [
        { image: "/Asets/inside_banner/ash_amaria/MicrosoftTeams-image (24).png" },

      ],
      heading: "Ash Amaira Retail",

      content: "Ash Amaira started with a humble beginning in the year 2002 when Divya Bhatia a graduate from the prestigious (National Institute of Fashion Technology) and her husband Sunny Bhatia with his expertise in Retail and International Business started out to establish Ash Amaira as a luxury fashion accessories Brand that would stand apart in designing,quality and craftsmanship globally.",
      content2: "As a brand we are dedicated to creating innovative and timeless fashion accessories using Indian embroideries and craftsmanship that are rare and stand apart.",
      content3: "Our dedication to quality and Craftsmanship has catapulted us into the international markets where we have made indelible impressions with global boutiques, brands and departmental stores.",
      content4: "Ash Amaira products are sold at Prestigious International stores Like Harvey Nichols (Hong Kong), Ludwig Beck (Germany), Duty Free (Paris), Vakko (Turkey), Robinsons (Dubai) among many others. ",
      content5: "Our flagship stores at the Iconic Grand Hyatt (Dubai), Taj Mahal Palace (Mumbai), Dlf Emporio Mall and the prestigious Khan Market in New Delhi speak of the dedication and commitment that Ash Amaira stands for!",
      subimage: [
        { image: "/Asets/inside_banner/ash_amaria/MicrosoftTeams-image (25).png" },
        { image: "/Asets/inside_banner/ash_amaria/MicrosoftTeams-image (26).png" },


      ]
    },
    {name: "/page/accessories",
      banners: [
        { image: "/Asets/inside_banner/ash_amaria/MicrosoftTeams-image (23).png" },
        
      ],
      heading: 'Ash Amaira Accessories Trading',
      content: "Ash Amaira started with a humble beginning in the year 2002 when Divya Bhatia a graduate from the prestigious (National Institute of Fashion Technology) and her husband Sunny Bhatia with his expertise in Retail and International Business started out to establish Ash Amaira as a luxury fashion accessories Brand that would stand apart in designing,quality and craftsmanship globally.",
      content2: "As a brand we are dedicated to creating innovative and timeless fashion accessories using Indian embroideries and craftsmanship that are rare and stand apart.",
      content3: "Our dedication to quality and Craftsmanship has catapulted us into the international markets where we have made indelible impressions with global boutiques, brands and departmental stores.",
      content4: "Ash Amaira products are sold at Prestigious International stores Like Harvey Nichols (Hong Kong), Ludwig Beck (Germany), Duty Free (Paris), Vakko (Turkey), Robinsons (Dubai) among many others. ",
      content5: "Our flagship stores at the Iconic Grand Hyatt (Dubai), Taj Mahal Palace (Mumbai), Dlf Emporio Mall and the prestigious Khan Market in New Delhi speak of the dedication and commitment that Ash Amaira stands for!",

      subimage: [
        { image: "/Asets/inside_banner/ash_amaria/MicrosoftTeams-image (27).png" },


      ]
    },
    {name: "/page/gulf",
      banners: [
        { image: "/Asets/inside_banner/gulf/100_0735_1280x450.jpg" },
        { image: "/Asets/inside_banner/gulf/security_1280x450.jpg" },

      ],
      heading: 'Gulf Security Services LLC',

      content6:<><p>Gulf Security Services LLC (GSS) is a leading Security Consultancy company headquartered in Muscat, with representations across several GCC countries. It is established in 2008 as JV with a British Consultancy firm which subsequently filed for bankruptcy, so full operations were taken over by Bahwan to deliver on its promises made to the clients. &nbsp;Our strategic business model is intricately designed to deliver exceptional security-oriented projects across Oman, the GCC countries, and the broader MENA region. We cater to both government sectors and private corporations, offering superior specialized security solutions that not only meet high standards but also optimize costs, setting us apart in the security market.</p>

      <p>As an independent provider of security consultancy services, GSS boasts a unique position with no affiliations to manufacturers or integrators. Our unwavering dedication is solely directed towards maximizing benefits for our clients. GSS has been instrumental in numerous significant infrastructure projects across the GCC Countries and the MENA region, contributing expertise in Master Planning, Security Assessments, Security Designs, ELV Designs, Implementation Oversight for Security and ELV Systems, Security Training, ROP/Civil Approvals, and the creation of Security Management Standard Operating Procedures.</p>
      
      <p>Our diverse portfolio spans key sectors, including Oil &amp; Gas, Renewable Energies, Utilities, Sea Ports, Airports, Banking, Healthcare, Residential &amp; Commercial Projects, Manufacturing and Production Facilities, Pharmaceuticals, Hospitality, Historical Cities and Tourism, High Rise Buildings, and Control Room Operations. GSS Services include but not limited to,</p>
      
      <ul>
        <li>Security Risk Assessment</li>
        <li>Security Planning</li>
        <li>Security &amp; ELV Systems Design</li>
        <li>Project Implementation</li>
        <li>Security Management</li>
        <li>Security Training</li>
        <li>Business Resilience</li>
        <li>Outsourced Security Staff (system O &amp;M)&nbsp;</li>
      </ul>
      
      <p>At GSS, we take pride in delivering top-tier security solutions tailored to meet the unique needs of our clients. Our dedicated specialized team ensures the safety and security of your assets and operations, enhancing the resilience of your organization in today's dynamic environment.</p>
      
      <p><strong>Contact :</strong></p>
      
      <p>P.O.Box 3429, P.C. 112, Ruwi, Muscat, Sultanate of Oman.<br></br>
      Phone: +968 2231 1691, Telefax: +968 2206 0905</p>
      
      <p>Mr. Lamri Aitouche, Lead Consultant &amp; Business Head<br></br>
      
         Email: <a href="mailto:lamri@gss-oman.com" target="_blank"><strong>lamri@gss-oman.com</strong></a></p>
      
      <p>To learn more about GSS, visit : <a href="https://eur01.safelinks.protection.outlook.com/?url=http%3A%2F%2Fwww.gss-oman.com%2F&amp;data=05%7C02%7Cvinesh%40sbigh.com%7C7b6e22c0126242867e8308dc38eaeccf%7C0e7fa6e7707f4e01bc385a324b78c57b%7C0%7C0%7C638447828850384342%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&amp;sdata=czyrvgCDjtaPf%2BxjBcU8maz8assqG0Klc%2BCZ%2Bi%2FZFxY%3D&amp;reserved=0" target="_blank"><strong>www.gss-oman.com</strong></a></p>
      
      <p>&nbsp;</p></>,
      subimage: [
        { image: "/Asets/inside_banner/gulf/shutterstock_357x200.jpg" },
        { image: "/Asets/inside_banner/gulf/Unarmed-guards-Austin_357x200.jpg" },


      ]
    },
    {name: "/page/loconav",
      banners: [
        { image: "/Asets/inside_banner/locnova/loco3.png" },

      ],
      heading: 'Loconav',

      content: "Loconav is Asia’s leading and world’s fastest-growing fleet-tech ecosystem that started in India and is now in over 60 emerging markets globally. With a single purpose of democratizing fleet technology, we at LocoNav continuously strive to make vehicles safer for drivers and businesses more efficient for fleet operators, while positively impacting climate sustainability.",
      content2: "We work hard to empower our clients with improved fleet utilization, reduced operating costs and enhanced driving behavior through our advanced analytics and AI-based SaaS platform.",
      content3: "Our value focused approach includes:",
      content5:
        <ul>
          <li>Helping fleet owners make informed decisions by leveraging data and rich analytics
          </li>
          <li>Ensuring optimal asset utilization and maintenance to improve net profit
          </li>
          <li>Saving time and money by automating processes
          </li>
        </ul>,

      subimage: [
        { image: "/Asets/inside_banner/locnova/loco1.png" },
        { image: "/Asets/inside_banner/locnova/loco2.png" },


      ]
    },
    {name: "/page/vanderlande",
      banners: [
        { image: "/Asets/inside_banner/vanderland/vanderland.png" },
        // { image: "" },
        // { image: "" },

      ],
      heading: 'Vanderlande',

      content6:
      <>
        <p>Vanderlande is a market-leading, global partner for future-proof logistic process automation in the warehousing, airports and parcel sectors. Its extensive portfolio of integrated solutions – innovative systems, intelligent software and life-cycle services – results in the realization of fast, reliable and efficient automation technology.</p>

        <p>The company focuses on the optimization of its customers’ business processes and competitive positions. Through close cooperation, it strives for the improvement of their operational activities and the expansion of their logistical achievements.</p>

        <p>Vanderlande’s warehousing solutions are the first choice for many of the largest global e-commerce players and retailers in food, fashion and general merchandise across the globe. The company helps them to fulfil their promise of same-day delivery for billions of orders, with nine of the 15 largest global food retailers relying on its efficient and reliable solutions.</p>

        <p>As a global partner for future-proof airport solutions, Vanderlande’s market-leading baggage handling systems and related passenger solutions are capable of moving over 4 billion pieces of baggage around the world per year. These are active in more than 600 airports, including 12 of the world’s top 20.</p>

        <p>Vanderlande is also a leading supplier of process automation solutions that address the challenges in the parcel market. More than 52 million parcels are sorted by its systems every day, which have been installed for the world’s leading parcel handling companies.</p>

        <p>Established in 1949, Vanderlande has more than 9,000 employees, all committed to moving its customers’ businesses forward at diverse locations on every continent. With a consistently increasing turnover of 2.4 billion euros, it has established a worldwide reputation over the past seven decades as a global partner for future-proof logistic process automation.</p>

        <p>Vanderlande was acquired in 2017 by Toyota Industries Corporation, which will help it to continue its sustainable profitable growth. The two companies have a strong strategic match, and the synergies include cross-selling, product innovations and research and development.</p>

        {/* <p>For more information : &nbsp;<a href="https://www.vanderlande.com/about-vanderlande/company-profile/" target="_blank">Click Here</a></p> */}
        </>,


      subimage: [
        { image: "/Asets/inside_banner/vanderland/vanderland2.png" },
        { image: "/Asets/inside_banner/vanderland/vanderland3.png" },


      ]
    },
    {name: "/page/cantiere",
      banners: [
        { image: "/Asets/inside_banner/centraia/centa.png" },
       
      ],
      heading: 'Cantiere Navale Vittoria (CNV)',
      content6:< ><p>An experience of nearly 100 years of shipbuilding must be continually fed by new and exciting challenges, to observe the world with heightened awareness and, at the same time, profound wisdom</p>

      <p>Cantiere Navale Vittoria has developed to become an industrial reality of international reference which, despite the adversities, continues to grow stronger. About 300 workers contribute to this success, between direct and induced employment. An agile and flexible structure, of great professionalism, which allows you to build boats with quality and speed.</p>
      
      <p>The pride of Cantiere Navale Vittoria are the boats dedicated to safety, chosen by the Police and Coast Guards of many Mediterranean countries. Unsinkable and self-righting search and rescue vessels (in technical terms, SAR patrol boats) are particularly in demand.</p>
      
      <p>Furthermore, in the field of defence, the experience on patrol boats, fast interceptors and patrol boats in general is solid.</p>
      
      <p>In recent years, around a hundred have been built for numerous prestigious customers: coast guards of Italy, Slovenia, Croatia, Libya, Malta, Cyprus, Tunisia, Greece, Malta and many others.</p>
      
      <p>Our commitment</p>
      
      <p>We define our objectives aware of the values of our Company, of the dynamic evolution of the context in which we operate and of the interested parties involved. We firmly believe that innovation, new technologies and new ways of thinking are crucial to overcome future challenges.</p>
      
      {/* <p>For more information : &nbsp;<a href="https://www.vittoria.biz/en/profile/" target="_blank">Click Here</a></p> */}
      </>,

      subimage: [
        { image: "/Asets/inside_banner/centraia/centra1.png" },
        { image: "/Asets/inside_banner/centraia/centra2.png" },


      ]
    },
    {name: "/page/bahwan_lamnalco",
      banners: [
        { image: "/Asets/inside_banner/vanderland/bahwan_lam.png" },
       

      ],
      heading: 'Bahwan Lamnalco',

      content6: <>
      <p>Marine Services Inspection and maintenance of oil export terminal offshore facilities Maintenance and upkeep of Oil Response equipment Assistance in Oil Spill Response Maintenance and upkeep of Maintenance Barge Water transportation Berthing vessels alongside Jettys Marine Pilotage</p>

      <p>Support Collateral</p>
      
      <p>View all the marketing materials like brochures, images and presentation.</p>
      
      <p>Lookout</p>
      
      <p>Check our targeted products and services based on national and international market.</p>
      
      <p>Team Info</p>
      
      <p>View the complete team structure and employee profile details.</p>
      
      <p>Career Bank</p>
      
      <p>Check our hot job openings and apply for the preferred job.</p></>,
     

      subimage: [
        // { image: "" },
        // { image: "" },


      ]
    },
    {name: "/page/apollo_muscat",
      banners: [
        { image: "/Asets/inside_banner/apollo/apolo1.png" },
      

      ],
    
      heading: 'Apollo Muscat ',
      content6: <><p>Apollo Hospital Muscat is a well-known healthcare provider in the Sultanate of Oman which provides the most modern state-of-the-art healthcare services under one roof with highly motivated and dedicated professionals.</p>

      <p>Apollo Hospital Muscat is a 50 bedded multi-specialty hospital which strengthens the healthcare infrastructure in the Sultanate of Oman, setting a new benchmark in quality standards in healthcare delivery. Keeping with the tradition of delivering healthcare at par with the best in the world, Apollo relies a great deal on innovation, introspection, and improvement to render tender loving care to patients.</p>
      
      <p>Apollo Hospital Muscat provides holistic healthcare that includes prevention, treatment, rehabilitation and health education for patients, their families and clients by touching their lives.</p>
      
      {/* <p>For more information : &nbsp;<a href="https://www.apollohospitalmuscat.com/" target="_blank">Click Here</a></p> */}
      </>,

     

      subimage: [
        { image: "/Asets/inside_banner/apollo/apolo6.png" },
        { image: "/Asets/inside_banner/apollo/apolo5.png" },


      ]
    },
    {name: "/page/international",
    banners: [
      { image: "/Asets/inside_banner/apollo/apolo2.png" },
    
    ],
    heading: 'International Medical Center LLC',

   content6:<>
   <p>The International Medical Center Hospital adopts the mission of creating a unique model of health care with a holistic approach by healing body, mind and spirit by following international medical standards and perusing divine ethics. The hospital adopts the principle of "Healing by Design" by introducing elements that help in the healing process such as natural light in all patients' rooms and gardens all over the hospital, which create a proper healthy environment, while respecting patient’s privacy and dignity during his/her Stay.</p></>,

    subimage: [
      { image: "/Asets/inside_banner/apollo/apolo4.png" },


    ]
    },
    {name: "/page/super",
  banners: [
    { image: "/Asets/inside_banner/apollo/apolo1.png" },
   
  ],
  heading: 'Apollo Super Speciality Hospital LLC ',

  content6: <>
  <p>Today is a landmark day for Apollo as we complete 39 years of dedicated patient care. Over the years Apollo has been he pioneer of healthcare in India, and we are humbled by the love and trust placed in us by over 200 million patients and their families. It has motivated us to put in tireless efforts and remain unwavering in our commitment to bring you the best healthcare in the world.</p></>,
 

  subimage: [
    { image: "/Asets/inside_banner/apollo/apolo3.png" },
 

  ]
    },
    {name: "/page/north",
banners: [
  { image: "/Asets/inside_banner/north&ipark/north1.png" },
 

],
heading: 'North Ocean Industries ',

content6:<><p>North Ocean group of companies is a leading multi-disciplined business group established in the year 2000, having its corporate office in Muscat, Sultanate Of Oman. It has grown today to become a well-known group, over 1500 employees with the business operations in Oman and U.A.E. NOE group is well known for its services and manufacturing facilities in the field of construction, realty, manufacturing, education, agriculture etc.</p>

<p>Today NOE group offers many branded products for consumers under one roof, like Al Zain Poultry, Winkool -UPVC windows, I Secure -Security Solutions, Timber, Hyundai-Elevators and escalators, I park- Auto parking systems, I Space- Complete home and kitchen Solutions, Synergy- Electrical products, I Decore-cast aluminum products, Eurokool-Air conditioners etc.</p></>,

subimage: [
  { image: "/Asets/inside_banner/north&ipark/north2.png" },



]
    },
    {name: "/page/intelligent",
banners: [
  { image: "/Asets/inside_banner/north&ipark/ipark.png" },
 

],
heading: 'Intelligent Parking Elevator',

content6:<><p>I Park is a prominent provider of personalized automobile parking solutions in the Middle Eastern region. The company, which has been operational since 2012, boasts of a 15-year track record in the realm of automated car parking systems, thus earning it a reputable and trustworthy name within the industry. I Park's adherence to ISO standards for designing, producing, and maintaining automated car parking systems ensures top-notch solutions for their clients.  </p>

<p>I Park's manufacturing facilities are situated in Al Amerat, Sultanate of Oman, where they offer a variety of solutions, including Tower Parking, Basement Cart Parking (without ramps), Puzzle Parking, Basement Sliding Puzzle (without ramps), and Stacker Parking. The company's integration of advanced automation and robotics technology from Korea has made it possible to provide over 1000+ car parking spaces in Oman, covering the entire spectrum from concept design, detailed engineering and design, manufacturing, installation and maintenance.</p>

<p>What distinguishes I Park from other car parking solutions providers in the Middle East is its unique capacity as the sole manufacturer and solution provider for multi-level automated and robotic car parking systems. With manufacturing plants in Oman and KSA, I Park offers tailored solutions to meet the distinct parking needs of their clients.</p></>,

subimage: [
  { image: "/Asets/inside_banner/north&ipark/ipark2.png" },


]
    },
    {name: "/page/ipark",
banners: [
  { image: "/Asets/inside_banner/north&ipark/ipark.png" },
 

],
heading: 'I Park Robotic Parking and Elevators',

content6:<>
<p>I Park is a prominent provider of personalized automobile parking solutions in the Middle Eastern region. The company, which has been operational since 2012, boasts of a 15-year track record in the realm of automated car parking systems, thus earning it a reputable and trustworthy name within the industry. I Park's adherence to ISO standards for designing, producing, and maintaining automated car parking systems ensures top-notch solutions for their clients. </p>

<p>I Park's manufacturing facilities are situated in Al Amerat, Sultanate of Oman, where they offer a variety of solutions, including Tower Parking, Basement Cart Parking (without ramps), Puzzle Parking, Basement Sliding Puzzle (without ramps), and Stacker Parking. The company's integration of advanced automation and robotics technology from Korea has made it possible to provide over 1000+ car parking spaces in Oman, covering the entire spectrum from concept design, detailed engineering and design, manufacturing, installation and maintenance.</p>
<p>What distinguishes I Park from other car parking solutions providers in the Middle East is its unique capacity as the sole manufacturer and solution provider for multi-level automated and robotic car parking systems. With manufacturing plants in Oman and KSA, I Park offers tailored solutions to meet the distinct parking needs of their clients.</p></>,

subimage: [
 
  { image: "/Asets/inside_banner/north&ipark/ipark2.png" },


]
    },
    {name: "/page/bstlprofile",
banners: [
  { image: "https://admin.bstlglobal.com/storage/uploads/FBN9kjDCzeXOeCaUUq9BmqgyMdZ1YrPW1Uwk0QeE.jpg" },
 

],
heading: 'Bahwan Services and Trading LLC (BST) ',

content6:<><p>Bahwan Services and Trading (BST) is Limited Liability Company from Sultanate of Oman, and is a global, diversified organization of 30 major businesses operating in GCC and Asia as Limited Liability Companies, Joint Ventures or Representations of internationally reputed European and American brands, wholly owned subsidiaries, and a few Start-Ups. BST delivers end-to-end solutions in 8 business segments, namely</p>

<ul>
	<li>
	<p>Energy &amp; Energy Management</p>
	</li>
	<li>
	<p>Food</p>
	</li>
	<li>
	<p>Healthcare</p>
	</li>
	<li>
	<p>Asset Security &amp; Asset Management</p>
	</li>
	<li>
	<p>Packaging &amp; Logistics</p>
	</li>
	<li>
	<p>Construction &amp; Infrastructure &amp; Real Estate Projects,</p>
	</li>
	<li>
	<p>Fashion &amp; Retail</p>
	</li>
	<li>
	<p>Financial Services</p>
	</li>
</ul>

<p>We are descendants of Oman’s oldest and most respected business-house Suhail Bhawan Group which was started back in 1965. SB Group is actively involved in Automobile Distributorship business, has been at the forefront of developing many megaprojects and iconic landmarks in Oman. Our dedicated workforce of over 20,000 people in over 10 countries is focused on sustainable developments, global partnerships.</p>

<p>BST is currently working on agenda of business diversification, and towards the fulfilment of Oman’s Vision 2030 with focus areas as generate employment, bring new technologies and skill development.</p></>

    },

  ]




  const location = useLocation()
  const newLocation = location.pathname

  useEffect(()=>{
    window.scrollTo(0, 0);


  },[newLocation])

  return (
    <div>
    
<>
      {insidedata.map((item, index) => {
        return (

          item.name === newLocation &&
          <>
            <Carousel>
              {item.banners && item.banners.map((img) => {
                return (
                  <Carousel.Item >
                    <img src={img.image} width={'100%'}  />
                  </Carousel.Item>
                )
              })}
            </Carousel>



            <Container fluid>
              <Row className='new-dynamic-page-row'>

                <Col lg={8} style={{ paddingRight: '30px' }}>
                  <h3>{item.heading}</h3>
                  <p style={{ fontSize: '14px', textAlign: 'justify' }}>{item.content}</p>
                  {item.content2 &&
                    <p style={{ fontSize: '14px', textAlign: 'justify' }}>{item.content2}</p>}
                  {item.content3 &&
                    <p style={{ fontSize: '14px', textAlign: 'justify' }}>{item.content3}</p>}
                  {item.content4 &&
                    <p style={{ fontSize: '14px', textAlign: 'justify' }}>{item.content4}</p>}
                  {item.content5 &&
                    <p style={{ fontSize: '14px', textAlign: 'justify' }}>{item.content5}</p>}
                  {item.content6 && 
                  
                  
                  <div style={{textAlign:"justify"}} className='inside-div'>
                  {item.content6}
                  </div>
                  
                  }

                </Col>
                <Col lg={4} className='new-dynamic-p-div'>
                  {/* {item.links && item.links.map((item) => {
                    return (
                      item.name ?
                        <div className='new-dynamic-p-div-link-div'>
                          <p className='new-dynamic-p' style={{ width: '100%' }}><Link to={item.url}> <FaAngleRight />
                            {item.name}</Link></p>
                        </div>
                        : null
                    )
                  })} */}

                  <div style={{ height: "100%" }}>
                    {item.subimage && item.subimage.map((item) => {
                      return (
                        <img style={{ objectFit: 'cover' }} src={item.image} className='mt-3' height={'200px'} width={'100%'} />
                      )
                    })}
                  </div>

                </Col>

              </Row>

            </Container>

          </>

        )
      })}
      </>
 
    
    </div>
  )

}

export default Dyanamic_pages
