import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import Parser from 'html-react-parser';
import axios from 'axios';
import { Carousel, Col, Container, Row } from 'react-bootstrap'

export default function SingleNews() {
    console.log("loaded")
    const [singleArticle, setSingleArticle] = useState();
    var url = document.URL
    var page_name = url.substr(url.lastIndexOf('/') + 1);
    const location = useLocation();
   
    useEffect(() => {
        console.log("functon calling")
        axios.get('https://admin.bstlglobal.com/api/v1/single_news/' + page_name)
            .then(response => setSingleArticle(response.data))
            .catch(error => console.log(error))
    }, [location])
    return (
        <>
            {singleArticle &&
               <>
                   
                       
                            <Carousel  indicators={false} controls={false} >
                                <Carousel.Item>
                                 <img src={"https://admin.bstlglobal.com/"+JSON.parse(singleArticle.images)[1]}  width={'100%'} />
                        </Carousel.Item>
                        </Carousel>
                
                    
         

      <Container className='mt-5 mb-5'>           
     <Row style={{ display: 'flex', justifyContent: 'center' }}>
                        <Col lg={12} style={{ marginRight: '2%', marginLeft: '2%' }}>
                            <div style={{ textAlign: "justify" }} >
                                <h4 style={{ fontSize: '21px' }}>{singleArticle.title}</h4>
                                <h6 style={{ fontSize: '15px' }}>{singleArticle.sub_title}</h6>

                                <p style={{ fontSize: '14px', textAlign: 'justify' }} >
                                    {Parser(singleArticle.content)}
                                </p>
                            </div>
                        </Col>
                    </Row>
                    </Container> 
                    </>  
   }
        </>
    )
}
