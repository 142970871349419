import axios from 'axios'
import parse from 'html-react-parser';

import React, { useEffect, useState} from 'react'

function Staticpages() {

    
    const [data, setData]=useState();
    const[loading,setLoading]=useState(false)
    var url = document.URL
    var page_name=url.substr(url.lastIndexOf('/') + 1);
    

    console.log(page_name)
    console.log(page_name)
    useEffect(() => {
        axios.get('https://admin.bstlglobal.com/api/v1/static_page/'+page_name)
            .then(res => { setData(res.data); setLoading(true)})
    },)


    return (

        <>
       
        <div className='container mt-4 mb-4'>
        {data && parse(data.content)}
        </div>
       

        </>
    )
}

export default Staticpages
